body {
  background: #eaedef;
  font-family: Rubik, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #000c3f;
  margin: 0;
}

@media (max-width: 460px) {
  body {
    font-size: 12px;
  }
}
