.Page {
  margin: 0 auto;
  padding: 1.5rem;
  min-height: calc(100vh - 3rem);
  display: flex;
}

.Questionnaire_App {
  position: relative;
  margin: 0 auto;
  padding: 1.5rem;
  min-height: calc(100% - 3rem);
  display: flex;
  /*height: 100%;*/
  /*overflow: auto;*/
}

.Disclaimer {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-width: 620px;
}

@media (max-width: 768px) {
  .Disclaimer {
    height: 100%;
    overflow: hidden;
    padding: 0 1.5em;
    justify-content: initial;
  }

  .EvenPadding {
    padding: 1.5em;
    font-size: 1rem;
  }

  .Disclaimer > * {
    flex: 0 0 auto;
  }
}

.Page .PageIcon {
  height: 200px;
}

.PageColumn {
  flex-direction: column;
}

.PageCenter {
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
